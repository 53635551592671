import { ON_OFF_CONFIG_VALUES } from 'types/config';

export const DEFAULT_TIME_TIMER = 10;

export const GOOGLE_PLAY_DOWNLOAD_COUNT = 30;

export const BREAK_POINTS = {
    tablet: 768,
    desktop: 1440,
};

export const WALLPILATES_BRANCH_LIST: string[] = ['wallpilates', 'wallpilatesworkout', 'wallpilatesplan'];

export const YOGA_TYPES_BRANCH_LIST: string[] = ['lazy', 'stretching', 'antiaging', 'postpartum', 'mobility'];

export const BRANCH_LIST = [
    'sofa',
    'chair',
    'pilates',
    'exp',
    'meditation',
    'face',
    'somatic',
    'summer',
    'taichi',
    'somatic_male',
    'wallpilates_male',
    'wallpilates_female', // back-end expects this branch not to be mapped, so it must not be included in WALLPILATES_BRANCH_LIST
    ...WALLPILATES_BRANCH_LIST,
    ...YOGA_TYPES_BRANCH_LIST,
];

export const DEFAULT_WEIGHT = 85;

export const PROCESSING_FEE_COEF = 1.06;

export const DEFAULT_BMI = 25;

export const DEFAULT_AGE = 16;

export const DEFAULT_TARGET_WEIGHT = 85;
export const DEFAULT_CURRENT_WEIGHT = 90;

export const NO_CONFIG_VALUE = 'no';

export const OFF_CONFIG_VALUE: ON_OFF_CONFIG_VALUES = 'off';

export const ON_CONFIG_VALUE: ON_OFF_CONFIG_VALUES = 'on';

export const BRANCHES_WITH_FEEDBACK = ['chair', 'wallpilates', 'somatic', 'pilates'];

export const DEFAULT = 'default';
